import React, { Fragment } from 'react';
import AppMainRouter from './AppMainRouter';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const AppMain = () => {
  return (
    <Fragment>
      <Header/>
      <AppMainRouter />
      <Footer />
    </Fragment>
  )
}

export default AppMain;

