import Axios from 'axios';

const url = '/payments';

const paymentApi = {
  getCheck: (params) => {
    return Axios.get(`${url}/check`, {
      params
    });
  },
  exportCheck:() => {
    return Axios.get(`${url}/check/export`);
  },
  updateCheck:(id, params) => {
    return Axios.patch(`${url}/check/${id}`, params);
  },
  deleteCheck:(id) => {
    return Axios.delete(`${url}/check/${id}`);
  },
  uploadCheck:(file) => {
    let formData = new FormData();
    formData.append('file', file);
    return Axios.post(`${url}/check/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getPaymentsClaimError: (params) => {
    return Axios.get(`${url}/claim-ach-error`, {
      params
    });
  },
};


export default paymentApi;
