import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../reducers/ModalReducer/ModalAction';
import { paymentListSetData } from '../../../../reducers/PaymentReducer/PaymentAction';
import { spinner } from '../../../../reducers/UIReducer/UIAction';
import PaymentEditModal from '../Modals/PaymentEditModal';
import PaymentListRow from './PaymentListRow';
import paymentApi from './../../../../api/paymentApi';

const useApi = () => {
  const paymentList = useSelector(({payment}) => payment.paymentList);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const dispatch = useDispatch();

  const getCheck = (page, search) => {
    const params = {
      search: search,
      page: page,
      limit: 20
    };
    setIsLoading(true);
    dispatch(spinner(true));
    paymentApi.getCheck(params).then(({data}) => {
      dispatch(spinner(false));
      if(page === 1) {
        dispatch(paymentListSetData(data.data));
      } else {
        dispatch(paymentListSetData(paymentList.concat(data.data)));
      }
      setPage(page);
      setHasMore(data._meta.pageCount > data._meta.currentPage);
      setIsLoading(false);
    }).catch(err => {
      dispatch(spinner(false));
      console.error(err);
    });
  }

  const paymentEdit = (payment)=> {
    const modalPayment = {
      payment,
      getCheck
    };
    dispatch(showModal(PaymentEditModal, modalPayment));
  }

  const paymentMap = paymentList.map((p, i)=> (
    <PaymentListRow key={`paylist-${i}`} {...p} paymentEdit={paymentEdit} />
  ))

  useEffect(()=>{
    getCheck(page, '');
    // eslint-disable-next-line
  }, [])

  return {
    hasMore, 
    isLoading,
    getCheck,
    paymentList,
    paymentMap
  }
 
}


export default {
  useApi
} 
