export const selectStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        borderRadius: 2,
        borderColor: state.isFocused ? '#8D8D90' : '#D7D8D9',
        ':hover': {
          borderColor: '#D7D8D9'
        },
        boxShadow: 'none'
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        zIndex: 2
      };
    },
    valueContainer: (provided, state) => {
      return {
        ...provided,
        padding: '0.375rem 0.75rem'
      };
    },
    input: (provided, state) => {
      return {
        ...provided,
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        marginLeft: 0,
        marginRight: 0,
        fontSize: 16
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: '#76777B',
        marginLeft: 0,
        marginRight: 0,
        fontSize: 16
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        backgroundColor: 'none'
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        color: state.isFocused ? '#8D8D90' : '#76777B',
        ':hover': {
          color: '#8D8D90'
        }
      };
    },
  };