import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import * as $ from 'jquery';

const HeaderLogo = ({logo}) => {
  const [h, setH] = useState(0);

  useEffect(() => {
    const h = $('#header-container').outerHeight();
    setH(h);
  }, [setH]);

  return (
    <div style={{height: `${h}px`}}>
      <Container id='header-container' fluid className='bg-blueDark p-2'>
        <Row>
          <Col className='text-center'>
            <img src={logo} alt='Company Logo' className='company-logo clickable' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

HeaderLogo.propTypes = {
  logo: PropTypes.string.isRequired
};

export default HeaderLogo;