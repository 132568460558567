export const userSetData = (data) => {
  return {
    type: 'USER_SET_DATA',
    payload: data
  };
};
  
export const userClearData = () => {
  return {
    type: 'USER_CLEAR_DATA',
  };
};
  