import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import TopBar from '../TopBar/TopBar';
import * as $ from 'jquery';

const Header = () => {
  const [h, setH] = useState(0);
  useEffect(() => {
    const h = $('#header-container').outerHeight();
    setH(h);
  }, [setH]);
  return (
    <div style={{height: `${h}px`}}>
      <Container id='header-container' fluid className='bg-blueDark px-4'>
        <TopBar />
      </Container>
    </div>
  )
}

export default Header;
