import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';

const PaymentListHeader = () => {
  return (
    <CardBody className='border-bottom py-2'>
      <Row className='text-size-10 line-height-11 text-gray'>
        <Col xs={1}>
          PAYMENT ID
        </Col>
        <Col xs={3}>
          LOCATION NAME
        </Col>
        <Col xs={2}>
          TIME FRAME
        </Col>
        <Col xs={1}>
          AMOUNT
        </Col>
        <Col xs={1}>
          GPO
        </Col>
        <Col xs={1}>
          CLAIMED
        </Col>
        <Col xs={1}>
          CLAIMED DATE
        </Col>
        <Col xs={1}>
          DETAILS ONLY
        </Col>
        <Col xs={2}>
        </Col>
      </Row>
    </CardBody>
  )
}

export default PaymentListHeader;
