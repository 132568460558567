import React from 'react';
import {Row, Col} from 'reactstrap';
import { IoMdLogOut } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

const TopBar = () => {
  const history = useHistory();
  const logout = () => {
    history.push('/logout');
  };

  return (
   <Row>
    <Col style={{maxWidth: '250px', height:'70px'}}>
      <img src={process.env.REACT_APP_COMPANY_LOGO} alt='Company Logo' className='company-logo'/>
    </Col>
    <Col/>
    <Col className='text-white text-size-12 clickable mw-fc d-flex justify-content-end align-items-center' onClick={logout}>
      LOGOUT &nbsp;
      <IoMdLogOut color={'white'} size={18} />
    </Col>
   </Row>
  )
}

export default TopBar;
