import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Row, Col, Form, FormGroup, Label, Input, CustomInput, Tooltip } from 'reactstrap';
import { selectStyles } from '../../../../constants/styles';
import { BsTrash } from 'react-icons/bs';
import { IoMdInformationCircle } from 'react-icons/io';
import Select from 'react-select';
import useModal from '../../../../hooks/useModal/useModal';
import useEditFormModal from '../Modals/useEditFormModal';

const PaymentEditModal = ({id, ...props}) => {
  const {modalProps, headerProps, bodyProps, toggle} = useModal(id, {isMenu: true, isFullscreen: true, header: {background: 'white', color:'black'}});
  const {payment, getCheck} = props;
  const {paymentId, qbCode, merchant, amount, timeframe, gpo, checkDetailsOnly, onCheckDetailsOnly, savePayment, deletePayment, validate } = useEditFormModal(payment);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTool = () => setTooltipOpen(!tooltipOpen);
  const paymentParams = {
    id: paymentId.value,
    merchant: merchant.value,
    amount: amount.value,
    timeframe: timeframe.value,
    gpo: gpo.value,
    details_only: checkDetailsOnly,
    validate,
    getCheck,
    toggle
  }

  const options = [
    { value: 1, label: 'DA' },
    { value: 2, label: 'DVPG' },
    { value: 3, label: 'CC' },
    { value: 4, label: 'S1P' },
    { value: 5, label: 'SS' },
    { value: 6, label: 'NGCRA' },
    { value: 7, label: 'FRPG' },
    { value: 8, label: 'ALCN' },
    { value: 9, label: 'PS' },
    { value: 10, label: 'RPP' },
    { value: 11, label: 'RAD' },
    { value: 12, label: 'BESTWESTERN' },
  ];

  return (
   <Modal  {...modalProps}>
      <ModalHeader {...headerProps}>Edit Payment</ModalHeader>
      <ModalBody {...bodyProps} >
        <Form className='px-4'>
          <FormGroup >
            <Label className='text-size-12 line-height-14'>Payment ID</Label>
            <Input {...qbCode} disabled={true} />
          </FormGroup>
          <FormGroup >
            <Label className='text-size-12 line-height-14'>Location</Label>
            <Input {...merchant} />
          </FormGroup>
          <FormGroup >
            <Row>
              <Col>
                <Label className='text-size-12 line-height-14'>Time Frame</Label>&nbsp;
                <IoMdInformationCircle id='idTooltipTimeFrame' className='clickable' color='blue' size={17} />
                <Tooltip target='idTooltipTimeFrame' className='opacity-100' placement='right' isOpen={tooltipOpen} innerClassName='bg-white text-black p-0 border rounded-sm' hideArrow toggle={toggleTool}>
                  Must be in 7 character format. Example: Q4-2019
                </Tooltip>
                <Input {...timeframe} />
              </Col>
              <Col >
                <Label className='text-size-12 line-height-14'>Amount</Label>
                <Input {...amount} />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup >
            <Label className='text-size-12 line-height-14'>GPO</Label>
            <Select options={options} onChange={gpo.onChange} value={gpo.value || ''} styles={selectStyles} />
          </FormGroup>
          <FormGroup>
            <Row>
              <Col>
                <CustomInput id={1} label={'Details Only'} type='checkbox' checked={checkDetailsOnly} onChange={()=>onCheckDetailsOnly(checkDetailsOnly)} />
              </Col>
              <Col>
                <BsTrash onClick={()=>deletePayment(paymentParams)} className='clickable' color='gray' size={16} /> &nbsp;
                <Label className='text-size-14 line-height-16'>Delete this payment</Label>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs={5}>
                <Button onClick={()=>savePayment(paymentParams)} block className='p-2' color='blue'>SAVE</Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default PaymentEditModal;
