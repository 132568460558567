import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';

export const PaymentListErrorHeader = () => {
  return (
    <CardBody className='border-bottom py-2'>
      <Row className='text-size-10 line-height-11 text-gray'>
        <Col xs={1}>
          PAYMENT ID
        </Col>
        <Col xs={2}>
          LOCATION NAME
        </Col>
        <Col xs={1}>
          ERROR TIME
        </Col>
        <Col xs={8}>
          ERROR DETAILS
        </Col>
      </Row>
    </CardBody>
  )
}

