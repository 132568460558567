import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin, IoLogoInstagram } from 'react-icons/io';

const Footer = () => {
  return (
    <Container fluid className='bg-blueDark text-white p-4'>
      <Row className='px-5 pb-4'>
        <Col>
        </Col>
        <Col className='mw-fc text-right'>
          <Row>
            <Col>
              <IoLogoFacebook color={'inherit'} size={30} />
            </Col>
            <Col>
              <IoLogoTwitter color={'inherit'} size={30} />
            </Col>
            <Col>
              <IoLogoLinkedin color={'inherit'} size={30} />
            </Col>
            <Col>
              <IoLogoInstagram color={'inherit'} size={30} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='px-5'>
        <Col className='text-white'>
          <hr  style={{borderColor:'inherit'}}/>
        </Col>
      </Row>
      <Row  className='px-5'>
        <Col>
          Buyers Edge Platform
        </Col>
      </Row>
    </Container>
  )
}

export default Footer;
