import { useDispatch } from 'react-redux';
import { userSetData } from '../../reducers/UserReducer/UserAction';
import LocalStorage from '../../components/LocalStorage/LocalStorage';

const useLogin = () => {
  const dispatch = useDispatch();

  const login = (dataUser) => {
    const { data } = dataUser;
    LocalStorage.authKey.set(data.authKey);
    LocalStorage.userData.set(data);
    dispatch(userSetData(data));
  };

  return {
    login
  };
};

export default useLogin;