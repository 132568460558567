import React from 'react';
import { Container } from 'reactstrap';
import PaymentTop from './PaymentTop';
import PaymentList from './PaymentList/PaymentList';
import { useSelector } from 'react-redux';
import usePaymentList from '../Payment/PaymentList/usePaymentList';
import { PaymentTab } from './PaymentTab';
import usePayment from './usePayment';
import { usePaymentListError } from './PaymentListError/usePaymentListError';
import { PaymentListError } from './PaymentListError/PaymentListError';

const Payment = () => {
  const paymentListError = useSelector(({payment}) => payment.paymentListError);
  const { getCheck, paymentMap, hasMore, isLoading } = usePaymentList.useApi();
  const { activeTab, setActiveTab } = usePayment.useTab();
  const { getListError, hasMoreError, isLoadingError } = usePaymentListError();
  
  const propsList = {
    getCheck,
    paymentMap,
    hasMore,
    isLoading
  }
  const propsTop = {
    getCheck, 
    getListError, 
    activeTab
  }
  const propsTab = {
    setActiveTab,
    activeTab
  }
  const propsListError = {
    getListError,
    paymentListError,
    hasMoreError, 
    isLoadingError
  }

  return (
    <Container fluid className='bg-grayBg flex-grow-1 px-4 pb-4'>
      <PaymentTop {...propsTop } />
      <PaymentTab {...propsTab}  />
      { activeTab === 'home' &&
        <PaymentList {...propsList} />
      }
       { activeTab === 'error' &&
        <PaymentListError {...propsListError} />
      }
    </Container>
  )
}

export default Payment;
