import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { If, Then, Else } from 'react-if';
import AppRouter from './AppRouter';
import Modals from './components/Modals/Modals';
import useAxios from './hooks/useAxios/useAxios';
import Spinner from './components/Spinner/Spinner';

function App() {
  const ready = useAxios();
  return (
    <Fragment>
      <If condition={!ready}>
        <Then>
          <Container />
        </Then>
        <Else>
          <AppRouter/>
          <Modals />
          <Spinner />
        </Else>
      </If>
    </Fragment>
  );
}

export default App;
