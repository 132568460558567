import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import LocalStorage from '../../components/LocalStorage/LocalStorage';

const useAxios = () => {
  const [ready, setReady] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setReady(true);
    Axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    Axios.defaults.headers['Pragma'] = 'no-cache';
    Axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8';

    Axios.interceptors.request.use(config => {
      const token = LocalStorage.authKey.get();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });

    Axios.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response && error.response.status === 401) {
        history.push('/logout');
      }
      if (error.response) {
        return Promise.reject(error.response);
      } else {
        return Promise.reject(error);
      }
    });
    // eslint-disable-next-line
  }, []);

  return ready;
};

export default useAxios;