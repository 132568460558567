import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import paymentApi from '../../../../api/paymentApi';
import { spinner } from '../../../../reducers/UIReducer/UIAction';
import { paymentListErrorSetData } from '../../../../reducers/PaymentReducer/PaymentAction';

export const usePaymentListError = () => {
  const [page, setPage] = useState(1);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [hasMoreError, setHasMoreError] = useState(false);
  const dispatch = useDispatch();
  const paymentListError = useSelector(({payment}) => payment.paymentListError);

  useEffect(()=> {
    getListError(1, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getListError = (page, search) => {
    const params = {
      search: search,
      page: page,
      limit: 20
    };
    setIsLoadingError(true);
    dispatch(spinner(true));
    paymentApi.getPaymentsClaimError(params).then(({data}) => {
      dispatch(spinner(false));
      if(page === 1) {
        dispatch(paymentListErrorSetData(data.data));
      } else {
        dispatch(paymentListErrorSetData(paymentListError?.concat(data.data)));
      }
      setPage(page);
      setHasMoreError(data._meta.pageCount > data._meta.currentPage);
      setIsLoadingError(false);
    }).catch(err => {
      dispatch(spinner(false));
      console.error(err);
    });
  }

  return {
    isLoadingError,
    hasMoreError,
    getListError,
    page
  }
}
