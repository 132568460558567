export default function reducer(state = {
  paymentList: [],
  paymentListError: [],
  searchList: ''
}, action) {
  switch(action.type) {
    case 'PAYMENT_LIST_SET_DATA':
      return {
        ...state,
        paymentList: action.payload
      };
    case 'PAYMENT_LIST_CLEAR_DATA':
      return {
        ...state,
        paymentList: []
      };
    case 'PAYMENT_LIST_SET_SEARCH':
      return {
        ...state,
        searchList: action.payload
      };
    case 'PAYMENT_LIST_CLEAR_SEARCH':
      return {
        ...state,
        searchList: ''
      };
    case 'PAYMENT_LIST_ERROR_SET_DATA':
      return {
        ...state,
        paymentListError: action.payload
      };
    case 'PAYMENT_LIST_ERROR_CLEAR_DATA':
      return {
        ...state,
        paymentListError: []
      };
    default:
      return state;
  };
};