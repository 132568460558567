import React from 'react';
import {Container} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { userClearData } from '../../../reducers/UserReducer/UserAction';
import LocalStorage from '../../../components/LocalStorage/LocalStorage';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

const Logout = () => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const history = useHistory();

  LocalStorage.clear();
  dispatch(userClearData());
  instance.logoutRedirect({
    account: instance.getAccountByHomeId(),
    postLogoutRedirectUri: '/',
    mainWindowRedirectUri: '/'
  });
  history.push('/login');

  return (
    <Container />
  );
};

export default Logout;
