import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import moment from 'moment';

export const PaymentListErrorRow = ({contact_name, qb_code, create_time, exception}) => {
  return (
    <CardBody className='py-2 border-bottom'>
      <Row className='text-size-14 line-height-16 py-3'>
        <Col xs={1}>
          {qb_code}
        </Col>
        <Col xs={2}>
          {contact_name}
        </Col>
        <Col xs={1}>
          {create_time ? moment(create_time).format('MM/DD/YYYY'): ''}
        </Col>
        <Col xs={8}>
          {exception}
        </Col>
      </Row>
    </CardBody>
  )
}

