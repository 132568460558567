import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import moment from 'moment';
import numeral from 'numeral';

const PaymentListRow = ( {id, qb_code, merchant, timeframe, amount, gpo, claimed, requested_date, details_only, paymentEdit}) => {
  const propsPayment = {
    id,
    qb_code,
    merchant,
    timeframe,
    amount,
    gpo,
    claimed,
    requested_date,
    details_only
  }
  return (
    <CardBody className='py-2 border-bottom'>
      <Row className='text-size-14 line-height-16 py-3'>
        <Col xs={1}>
          {qb_code}
        </Col>
        <Col xs={3}>
          {merchant}
        </Col>
        <Col xs={2}>
          {timeframe}
        </Col>
        <Col xs={1}>
          {numeral(amount).format('$0,0.00')}
        </Col>
        <Col xs={1}>
          {gpo}
        </Col>
        <Col xs={1}>
          {requested_date ? 'Yes' : 'No'}
        </Col>
        <Col xs={1}>
          {requested_date ? moment(requested_date).format('MM/DD/YYYY'): ''}
        </Col>
        <Col xs={1}>
          {details_only ? 'True' : 'False'}
        </Col>
        <Col xs={1} className='text-blue clickable' onClick={()=>paymentEdit(propsPayment)}>
          Edit
        </Col>
      </Row>
    </CardBody>
  )
}

export default PaymentListRow;
