import React, { useState, Fragment } from 'react';
// import { Input, Button, Form, FormGroup, Label, FormFeedback, FormText } from 'reactstrap';
import { Button, FormGroup, FormText } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { spinner } from '../../reducers/UIReducer/UIAction';
// import { FaEye } from 'react-icons/fa';
// import useLoginForm from './useLoginForm';
import useLogin from '../../hooks/useLogin/useLogin';
import authApi from '../../api/authApi';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { loginRequest } from '../../config/msAuthConfig';
import { useSelector } from 'react-redux';

const LoginForm = () => {
  const history = useHistory();
  // const {email, password, validate} = useLoginForm();
  const [apiError, setApiError] = useState('');
  // const [passwordType, setPasswordType] = useState({type:'password', color: 'gray'})
  const {login} = useLogin();
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const isLogin = useSelector(({user}) => user.isLogin);

  // const onLogin = (e) => {
  //   e.preventDefault();
  //   const errors = validate();
  //   if (!errors) {
  //     const params = {
  //       username: email.value,
  //       password: password.value
  //     };
  //     dispatch(spinner(true));
  //     authApi.login.post(params).then(({data}) => {
  //       dispatch(spinner(false));
  //       login(data);
  //     }).catch(err => {
  //       dispatch(spinner(false));
  //       let msg = `Something wen't wrong`
  //       if (err) {
  //         if (err.data && err.data.message) {
  //           msg = err.data.message;
  //         }
  //       }
  //       setApiError(msg);
  //     });
  //   }
  // }

  // const styleEye = {
  //   position: 'relative',
  //   top: '-28px',
  //   left: '345px'
  // }

  // const changePasswordType = () => {
  //   if(passwordType.type === 'password') {
  //     setPasswordType({type:'text', color: 'blue'});
  //   } else {
  //     setPasswordType({type:'password', color: 'gray'});
  //   }
  // }

  const handleMsalResponse = (res) => {
    if (!res) return;
    const params = {
      accessToken: res.accessToken,
      tenantId: res.tenantId,
      tokenType: res.tokenType,
      name: res.account && res.account.name,
      email: res.account && res.account.username,
      localEmail: 'admin@admin.com',
    };

    dispatch(spinner(true));
    authApi.loginMicrosoft.post(params)
      .then(({ data })=> {
        dispatch(spinner(false));
        // let result = data.data;
        // localStorage.setItem('authKey', result.authKey);
        // localStorage.setItem('email', result.email);
        // localStorage.setItem('isAnalyst', result.isAnalyst);
        // setToken(result.authKey);
        login(data);
        // this.props.history.push('/dashboard');
      }).catch(err => {
        dispatch(spinner(false));
        let msg = `Something wen't wrong`;
        if (err) {
          if (err.data && err.data.message) {
            msg = err.data.message;
          }
        }
        setApiError(msg);
      });
  }

  const loginPopup = () => {
    instance.loginPopup(loginRequest).then(handleMsalResponse);
  }

  const logout = () => {
    history.push('/logout');
  }

  return (
      <Fragment>
        { isLogin ?
          <Button color='primary' size='lg' onClick={logout}>Logout</Button>
        :
          <Button color='primary' size='lg' onClick={loginPopup}>Login with Microsoft</Button>
        }
        <FormGroup>
          <FormText color='red'>{apiError}</FormText>
        </FormGroup>
      </Fragment>
  );
  // return (
  //  <Form onSubmit={onLogin}>
  //     <FormGroup className='line-height-16 text-size-12'>
  //       <Label for='email' className='text-grayText'>Email</Label>
  //       <Input className='border-none' {...email} />
  //       <FormFeedback>{email.message}</FormFeedback>
  //     </FormGroup>
  //     <FormGroup className='line-height-16 text-size-12'>
  //       <Label for='password' className='text-grayText'>Password</Label>
  //       <Input {...password} type={passwordType.type} />
  //       <span className='clickable' style={styleEye} onClick={changePasswordType}><FaEye color={passwordType.color} size={18} /></span>
  //       <FormFeedback>{password.message}</FormFeedback>
  //     </FormGroup>
  //     <Button type='submit' block color='blue' className='py-2'>LOG IN</Button>
  //     <FormGroup>
  //       <FormText color='red'>{apiError}</FormText>
  //     </FormGroup>
  //   </Form>
  // )
}

export default LoginForm;
