import React from 'react'
import { Row, Col, CardBody } from 'reactstrap';

export const PaymentTab = ({setActiveTab, activeTab}) => {
  const activeTabStyle = {
    borderBottom: '3px solid #0071CE'
  };

  return (
    <CardBody className='py-0 bg-white'>
      <Row className='text-size-12 line-height-11 text-gray'>
        <Col xs={1} onClick={() => setActiveTab('home')} 
          className={`clickable py-2 mw-fc ${activeTab === 'home' ? 'font-weight-bold': ''}`}
          style={activeTab === 'home' ? activeTabStyle : {}}
          >
          HOME
        </Col>
        <Col xs={1} onClick={() => setActiveTab('error')} 
          className={`clickable py-2 mw-fc ${activeTab === 'error' ? 'font-weight-bold': ''}`}
          style={activeTab === 'error' ? activeTabStyle : {}}
          >
          ERRORS
        </Col>
      </Row>
    </CardBody>
  )
}
