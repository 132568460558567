import React from 'react';
import { If, Then, Else } from 'react-if';
import { useSelector } from 'react-redux';

const Spinner = () => {
  const enabled = useSelector(({ui}) => ui.spinner);
  return (
    <If condition={enabled > 0}>
      <Then>
        <div className='mask'>
        <div className='spinner'>
          <div className='bounce1'></div>
          <div className='bounce2'></div>
          <div className='bounce3'></div>
        </div>
        </div>
      </Then>
      <Else>
        <div className='mask hide'>
          <div className='spinner'>                            
          </div>
        </div>
      </Else>
    </If>
  );
};

export default Spinner;