import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { useSelector } from 'react-redux';
import AppMain from './views/AppMain/AppMain';
import Logout from './views/AppMain/Logout/Logout';
import Login from './views/Login/Login';
import { withMsal } from '@azure/msal-react';
// import { useMsal } from '@azure/msal-react';

const AppRouter = () => {
  const isLogin = useSelector(({user}) => user.isLogin);
  // const { accounts } = useMsal();
  // const isLogin = accounts.length > 0;
  return(
    <Fragment>
      <If condition={isLogin}>
        <Then>
          <Switch>
            <Route path='/' component={AppMain} />
            <Route exact path='/logout' component={Logout} />
          </Switch>
        </Then>
      <Else>
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route>
            <Redirect to='/login' />
          </Route>
        </Switch>
        </Else>
      </If>
    </Fragment>
    )
}

export default withMsal(AppRouter);
