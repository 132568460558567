import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Payment from './Payment/Payment';
import Logout from './Logout/Logout';

const AppMainRouter = () => {
  return (
    <Switch>
      <Route path='/payment' component={Payment} />
      <Route exact path='/logout' component={Logout} />
      <Route>
        <Redirect to='/payment/list' />
      </Route>
    </Switch>
  )
}

export default AppMainRouter

