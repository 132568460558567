import React from 'react';
import { Container,Card } from 'reactstrap';
import PaymentListHeader from './PaymentListHeader';
import InfiniteScroll from 'react-infinite-scroller';

const PaymentList = (props) => {
  const {getCheck, paymentMap, hasMore, isLoading} = props;
  return (
   <Container fluid className='bg-white px-0'>
     <Card>
      <PaymentListHeader />
      <InfiniteScroll
        initialLoad = {false}
        pageStart={0}
        loadMore={getCheck}
        hasMore={hasMore && !isLoading}
        useWindow={true} >
         {paymentMap }
      </InfiniteScroll>
     </Card>
    
    </Container>
  )
}

export default PaymentList;

