import React from 'react';
import { Container,Card } from 'reactstrap';
import { PaymentListErrorHeader } from './PaymentListErrorHeader';
import { PaymentListErrorRow } from './PaymentListErrorRow';
import InfiniteScroll from 'react-infinite-scroller';

export const PaymentListError = ({getListError, paymentListError, hasMoreError, isLoadingError}) => {
  return (
    <Container fluid className='bg-white px-0'>
      <Card>
        <PaymentListErrorHeader />
        <InfiniteScroll
          initialLoad = {false}
          pageStart={0}
          loadMore={getListError}
          hasMore={hasMoreError && !isLoadingError}
          useWindow={true} >
            { paymentListError?.map((p, i)=> (
                <PaymentListErrorRow key={`listError_${i}`} {...p}  />
            ))}
        </InfiniteScroll>
      </Card>
   </Container>
  )
}
