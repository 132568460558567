export const paymentListSetData = (data) => {
  return {
    type: 'PAYMENT_LIST_SET_DATA',
    payload: data
  };
};
    
export const paymentListClearData = () => {
  return {
    type: 'PAYMENT_LIST_CLEAR_DATA',
  };
};

export const paymentListSetSearch = (data) => {
  return {
    type: 'PAYMENT_LIST_SET_SEARCH',
    payload: data
  };
};

export const paymentListClearSearch = () => {
  return {
    type: 'PAYMENT_LIST_CLEAR_SEARCH',
  };
};

export const paymentListErrorSetData = (data) => {
  return {
    type: 'PAYMENT_LIST_ERROR_SET_DATA',
    payload: data
  };
};

export const paymentListErrorClearData = () => {
  return {
    type: 'PAYMENT_LIST_ERROR_CLEAR_DATA',
  };
};