import React, {useRef} from 'react';
import InputSearch from '../../../components/InputSearch/InputSearch';
import { Button, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import usePayment from './usePayment';

const PaymentTop = (props) => {
  const {getCheck, getListError, activeTab} = props;
  const {search, uploadFile, exportFile} = usePayment.useTop(props);
  const searchList = useSelector(({payment}) => payment.searchList);
  const inputFile = useRef(null);

  const downloadTemplate = () => {
    const excelEl = document.createElement('a');
    excelEl.href = 'https://s3.amazonaws.com/buyersedgepayments.com/resources/templates/ACH-Payment-template.xlsx';
    excelEl.click();
  }

  const uploadTemplate = (e) => {
    e.preventDefault();
    inputFile.current.value = '';
    inputFile.current.click();
  }

  const sendFile = (inputFile) => {
    const excelTypes = ['csv', 'xls', 'xlsx'];
    const file = inputFile.currentTarget.files[0];
    const extension = file.name.match(/[0-9a-z]+$/i)[0];
    const isTypeOk = excelTypes.indexOf(extension) !== -1;
    if(isTypeOk) {
      uploadFile(file)
    } 
  }

  const onSearch = () => {
    if(activeTab === 'home') {
      getCheck(1, searchList)
    } else {
      getListError(1, searchList)
    }
  }

  return (
    <Row className='py-3'>
      <Col className='text-size-18 line-height-21 d-flex align-items-center'>
        Upload History
      </Col>
      <Col>
        <InputSearch placeholder='Search...' {...search} />
      </Col>
      <Col className='mw-fc'>
        <Button block onClick={onSearch} className='p-2' size='sm' color='blue'>APPLY</Button>
      </Col>
      <Col className='mw-fc'>
        <Button block outline className='p-2' size='sm' onClick={downloadTemplate} color='primary'>DOWNLOAD TEMPLATE</Button>
      </Col>
      <Col className='mw-fc'>
        <Button block outline className='p-2' size='sm' onClick={exportFile} color='primary'>DOWNLOAD CHECK DATABASE</Button>
      </Col>
      <Col className='mw-fc'>
        <Button block className='p-2' size='sm' onClick={uploadTemplate} color='primary'>UPLOAD NEW</Button>
      </Col>
      <input type='file' id='file' onChange={sendFile} ref={inputFile} style={{display: 'none'}} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx, application/vnd.oasis.opendocument.spreadsheet"/>
    </Row>
  )
}

export default PaymentTop;
