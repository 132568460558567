import Axios from 'axios';

const url = '/payments';

const authApi = {
  login: {
    post: (params) => {
      return Axios.post(`${url}/auth`, params);
    }
  },
  loginMicrosoft: {
    post: (params) => {
      return Axios.post(`/auth/microsoft`, params);
    }
  }
};

export default authApi;
