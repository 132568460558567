import { createStore, combineReducers } from 'redux';
import modals from './ModalReducer/ModalReducer';
import user from './UserReducer/UserReducer';
import ui from './UIReducer/UIReducer';
import payment from './PaymentReducer/PaymentReducer';

const reducer = combineReducers({
  modals,
  user,
  payment,
  ui
});

export default createStore(reducer);