import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';
//import { colors } from '../../config/constants';
import { If } from 'react-if';

const InputSearch = (props) => {
  const { onChange, value, onEnter, placeholder, clearValue } = props;

  const onEnterKey = ({key}) => {
    if (onEnter) {
      if (key === 'Enter') {
        onEnter(value);
      }
    }
  };

  return (
    <Fragment>
      <Input type='text' placeholder={placeholder}
        onChange={onChange} onKeyDown={onEnterKey}
        value={value} style={{paddingLeft: '30px', paddingRight: '30px'}}
      />
      <BsSearch style={{top: '12px', left: '25px'}} className='position-absolute grayDark' size={15} />
      <If condition={value !== ''}>
        <MdClear style={{top: '12px', right: '25px'}} className='position-absolute clickable' size={15} onClick={clearValue} />
      </If>
    </Fragment>
  );
};

InputSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  clearValue: PropTypes.func,
  onEnter: PropTypes.func,
  placeholder: PropTypes.string
};

InputSearch.defaultProps = {
  placeholder: 'Search...'
};

export default InputSearch;