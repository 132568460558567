const authKey = {
  get: () => {
    const authKey = localStorage.getItem('authKey') ;
    return authKey ? authKey : null
  },
  set: (value) => {
    localStorage.setItem('authKey', value);
  }
};
  
const resetKey = {
  get: () => {
    const resetKey = localStorage.getItem('resetKey') ;
    return resetKey ? resetKey : null
  },
  set: (value) => {
    localStorage.setItem('resetKey', value);
  }
};
  
const userData = {
  get: () => {
    const userData = localStorage.getItem('userData');
    return userData ? JSON.parse(userData) : null
  },
  set: (value) => {
    localStorage.setItem('userData', JSON.stringify(value));
  }
};
  
const clear = () => {
  localStorage.removeItem('authKey');
  localStorage.removeItem('resetKey');
  localStorage.removeItem('userData');
};

const LocalStorage = {
  authKey,
  resetKey,
  userData,
  clear
};

export default LocalStorage;