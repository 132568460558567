import { useState, useEffect } from 'react';
import { spinner } from '../../../../reducers/UIReducer/UIAction';
import { showModal } from '../../../../reducers/ModalReducer/ModalAction';
import { useDispatch, useSelector } from 'react-redux';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import Joi from 'joi';
import useForm from '../../../../hooks/useForm/useForm';
import paymentApi from '../../../../api/paymentApi';

const initialState = {
  paymentId: '',
  qbCode: '',
  merchant: '',
  timeframe: '',
  amount: '',
  gpo:''
};

const reducer = (state, action) => {
  return {
    ...state,
    [action.field] : action.value
  };
};

const schema = Joi.object({
  paymentId: Joi.required(),
  qbCode: Joi.required(),
  merchant: Joi.string().min(3).required(),
  amount: Joi.number().required(),
  timeframe: Joi.string().min(7).required(),
  gpo: Joi.required()
});

const useEditFormModal = (payment) => {
  const { form, validate, dispatch } = useForm(reducer, initialState, schema);
  const [checkDetailsOnly, setCheckDetailsOnly] = useState(payment.details_only);
  const dispatchAction = useDispatch();
  const search = useSelector(({payment}) => payment.searchList);
  useEffect(() => {
    dispatch({field: 'paymentId', value: payment.id});
    dispatch({field: 'qbCode', value: payment.qb_code});
    dispatch({field: 'merchant', value: payment.merchant});
    dispatch({field: 'amount', value: payment.amount});
    dispatch({field: 'timeframe', value: payment.timeframe});
    dispatch({field: 'gpo', value: { label: payment.gpo || '' } });
    //eslint-disable-next-line
  }, []);

  form.gpo.onChange = (valueSelected) => {
    dispatch({field: 'gpo', value: valueSelected || ''})
  }

  const savePayment = (payment)=> {
    const errors = payment.validate();
    if(!errors) {
      const params = {
        id: payment.id,
        merchant: payment.merchant,
        amount: payment.amount,
        timeframe: payment.timeframe,
        gpo: payment.gpo.label,
        details_only: payment.details_only
      }
      payment.toggle();
      paymentApi.updateCheck(params.id, params).then(({data}) => {
        dispatch(spinner(false));
        payment.getCheck(1, search);
      }).catch(err => {
        dispatch(spinner(false));
      });
    }
  }

  const onCheckDetailsOnly = (detailsValue) => {
    setCheckDetailsOnly(!detailsValue)
  }

  const deletePayment = (payment) => {
    const modalProps = {
      title:'Do you want to delete this payment?',
      showTwoButtons: true,
      buttonLeftText: 'Cancel',
      buttonRightText: 'Delete',
      buttonLeftColor: 'blue',
      buttonLeftOutline: true,
      buttonRightClick: () => deletePaymentOk(payment),
      payment
    }
    dispatchAction(showModal(AlertModal, modalProps));
  }

  const deletePaymentOk = (payment) => {
    if(payment.id) {
      payment.toggle();
      paymentApi.deleteCheck(payment.id).then(({data}) => {
        dispatch(spinner(false));
        payment.getCheck(1);
      }).catch(err => {
        dispatch(spinner(false));
      });
    }
  }

  return {
    ...form,
    validate,
    savePayment,
    onCheckDetailsOnly,
    checkDetailsOnly,
    deletePayment
  };
};


export default useEditFormModal;