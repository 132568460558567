
import LocalStorage from '../../components/LocalStorage/LocalStorage';
const isLogin = LocalStorage.authKey.get() ? true : false
const userData = isLogin ? LocalStorage.userData.get() : null;

export default function reducer(state = {
  isLogin: isLogin,
  id: isLogin && userData ? userData.id : null,
  email: isLogin && userData ? userData.email : null,
  name: isLogin && userData ? userData.contactName : null,
}, action) {
  switch(action.type) {
    case 'USER_SET_DATA':
      return {
        ...state,
        isLogin: true,
      };
    case 'USER_CLEAR_DATA':
      return {
        ...state,
        isLogin: false
      };

    default:
      return state;
  };
};
